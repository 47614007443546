import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useState } from 'react';

const FormUpload = ({
  label,
  isCompactLabel, //For smaller label font-size
  name,
  uploadMessage,
  errorMessage,
  isOptional,
  className,
  ...rest
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  return (
    <div className="w-full mb-4">
      {label !== '' && (
        <label htmlFor={name} className={`${isCompactLabel ? 'type-sans-040' : 'type-upper-140'} inline-block mb-2`}>
          {label}
          {isOptional ? (
            <span className="type-upper-070 text-mono-400 pl-2">Optional</span>
          ) : (
            ''
          )}
        </label>
      )}
      <div
        className={cn(
          'w-full border-dotted border h-30 relative grid place-items-center p-6 transition-colors',
          {
            'border-mono-500': !errorMessage,
            'border-red': !!errorMessage,
            'bg-mono-100': isDraggingOver,
          },
        )}
      >
        <p className="text-center type-sans-130 text-mono-500">
          {isDraggingOver ? 'Drop your file here' : uploadMessage}
        </p>
        <input
          type="file"
          name={name}
          id={name}
          className="opacity-0 absolute inset-0 block"
          onDragOver={() => setIsDraggingOver(true)}
          onDragLeave={() => setIsDraggingOver(false)}
          onDrop={() => setIsDraggingOver(false)}
          {...rest}
        />
      </div>
      {errorMessage && (
        <span className="block mt-2 type-sans-030 text-red">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

FormUpload.defaultProps = {
  errorMessage: '',
  label: '',
  isOptional: false,
  className: '',
  uploadMessage: '',
};

FormUpload.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  isOptional: PropTypes.bool,
  className: PropTypes.string,
  uploadMessage: PropTypes.string,
};

export default FormUpload;
