import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles';
import ErrorIcon from '../../../static/images/icons/icon-error.svg';

const FormError = ({ children }) => (
  <div className={styles.error}>
    <img src={ErrorIcon} alt="Alert icon" className={styles.errorIcon} />
    <div className={styles.errorMessage}>{children}</div>
  </div>
);

FormError.defaultProps = {
  children: <></>,
};

FormError.propTypes = {
  children: PropTypes.node,
};

export default FormError;
