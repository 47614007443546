import React from 'react';
import PropTypes from 'prop-types';
import ScrollInView from '@src/utility/ScrollInView';
import FluidImage from '@src/utility/FluidImage';

const FeaturesModule = ({ data: { heading, features } }) => {
  return (
    <ScrollInView className="bg-white p-6 md:py-8 md:px-10">
      <h2 className="type-sans-630 lg:type-sans-930 mb-4 md:mb-6 md:w-3/5">
        {heading}
      </h2>
      <div className="mt-10 grid grid-cols-2 grid-rows-2 gap-x-16 gap-y-10">
        {features.map((feature) => (
          <div key={feature.caption} className="text-center">
            <div className="w-full md:w-3/4 mx-auto relative">
              <FluidImage
                src={feature.image}
                maxWidth={300}
                maxHeight={300}
                aspectRatio={1}
              />
            </div>
            <p className="type-sans-530 !text-xl !leading-tight md:mt-8">
              {feature.caption}
            </p>
          </div>
        ))}
      </div>
    </ScrollInView>
  );
};

FeaturesModule.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default FeaturesModule;
