import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as styles from './styles';

const FormSelect = React.forwardRef(
  (
    {
      label,
      name,
      errorMessage,
      children,
      className,
      disabledCondition,
      placeholder,
      isOptional,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={cn(styles.selectGroup, {
          [styles.selectGroupDisabled]: disabledCondition,
        })}
        key={name}
      >
        <label htmlFor={name} className={styles.selectLabel}>
          {label}
          {isOptional ? (
            <span className="type-upper-070 text-mono-300">Optional</span>
          ) : (
            ''
          )}
        </label>
        <select
          ref={ref}
          id={name}
          name={name}
          className={cn(styles.select, 'text-mono-500', className, {
            [styles.selectError]: errorMessage,
          })}
          {...rest}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {children}
        </select>
        {errorMessage && (
          <span className={styles.selectErrorMessage}>{errorMessage}</span>
        )}
      </div>
    );
  },
);

FormSelect.displayName = 'FormSelect';

FormSelect.defaultProps = {
  errorMessage: '',
  label: '',
  placeholder: '',
  className: '',
  disabledCondition: false,
  isOptional: false,
};

FormSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabledCondition: PropTypes.bool,
  isOptional: PropTypes.bool,
};

export default FormSelect;
