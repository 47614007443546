import PropTypes from 'prop-types';
import React from 'react';
import Link from '@utility/Link';
import imageUrlFor from '@helpers/imageUrlFor';
import ScrollInView from '@utility/ScrollInView/index';
import * as styles from './styles';
import PortableText from '@src/utility/PortableText';

const RichTextContentBox = ({
  data: { heading, subheading, link, logo, paragraph },
}) => {
  return (
    <div className={styles.richTextContentBox}>
      <ScrollInView className="bg-white p-6 md:py-8 md:px-10">
        {logo && logo[0] && (
          <img
            src={imageUrlFor(logo[0]).maxHeight(65).url()}
            alt={logo[0].altText ? logo[0].altText : ''}
            className={styles.logo}
          />
        )}
        {subheading && (
          <p className="type-upper-070 text-mono-400 mt-1 mb-2">{subheading}</p>
        )}
        <h2 className="type-sans-630 lg:type-sans-930 mb-4 md:mb-6">
          {heading}
        </h2>
        <PortableText
          blocks={paragraph.richText}
          className="type-sans-330 text-mono-500"
        />
        {link && link[0] && (
          <p className="mt-6 md:mt-8">
            <Link
              internalExternal
              to={link[0]}
              className="type-sans-030"
              aria-label={link[0].label}
            >
              {link[0].label}
            </Link>
          </p>
        )}
      </ScrollInView>
    </div>
  );
};

RichTextContentBox.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    paragraph: PropTypes.object.isRequired,
    logo: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.arrayOf(PropTypes.object),
  }),
};

RichTextContentBox.defaultProps = {
  data: {
    subheading: '',
    link: [],
    logo: [],
  },
};

export default RichTextContentBox;
